import React, {useContext, useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import connect from "react-redux/es/connect/connect";
import CurrencyInput from "react-currency-input-field";
import {FormText} from "react-bootstrap";
import translate from "../../message/avii";


const Deposit = (props) => {
    const account = useSelector((state) => state.account);
    const dispatch = useDispatch();
    const [subAccount, setSubAccount] = useState([]);
    useEffect(() => {
    }, []);

    let [price,setPrice] = useState(0);
    useEffect(async () => {
        setPrice(props.price);
    },[price]);


    let changeAccount = (el) => {
        let state = 0;
        props.accounts.forEach(element => {
            if (element.id === +el.target.value) {
                state = element
            }
        });
        if (state === 0) {
            setSubAccount([])
        } else {
            setSubAccount(state["sub_account"])
        }
    }

    function duplicate() {
        if (props.count === 0) {
            return (
                <button type="button" className="add-button" onClick={() => props.onAddItem(props.id)}>
                    <span className="icon-Plus"/>
                </button>
            )
        } else {
            return (
                <button type="button" className="remove-button" onClick={() => props.onDeleteItem(props.number)}>
                    <span className="icon-Cancel"/>
                </button>
            )
        }
    }


    function render() {
        return (
            <div className="form-row">
                <input name="id"
                       id="id"
                       type="hidden"
                       value={props.id}
                />
                <div className="col-md-12 form-group">
                    <label htmlFor="name">اسم له :</label>
                    <select className="form-control silver"
                            name="account_id"
                            value={props.account_id}
                            onChange={(el) => props.onChange(el, props.id) & changeAccount(el)}
                            id="account_id">
                        <option value="0">{translate.ar.forms["اختيار"]}</option>
                        {
                            props.accounts.map((item) =>
                            {
                                if(account && item.id === account.id && props.type === "receiver"){
                                    return <option value={item.id} selected="selected">{item.name}</option>;
                                }else{
                                    return <option value={item.id}>{item.name}</option>;
                                }
                            })
                        }
                    </select>
                    {props.errors && props.errors.account_id &&
                    <FormText className="text-white">{props.errors.account_id}</FormText>
                    }
                </div>
                <div className="col-md-12 form-group">
                    <label htmlFor="name">المبلغ :</label>
                    <CurrencyInput
                        id="price"
                        name="price"
                        className="form-control silver"
                        value={props.price}
                        decimalsLimit={2}
                        onValueChange={(value, name) => props.onChange({
                            target: {
                                value,
                                name
                            }
                        }, props.number)}
                    />
                    {props.errors && props.errors.price &&
                    <FormText className="text-white">{props.errors.price}</FormText>
                    }
                </div>
                <div className="col-md-12 form-group">
                    <label htmlFor="name">فرع الحساب :</label>
                    <select className="form-control silver"
                            name="subaccount_id"
                            value={props.subaccount_id}
                            onChange={(el) => props.onChange(el, props.id)}>
                        <option value="0">{translate.ar.forms["اختيار"]}</option>
                        {
                            subAccount.map((item) => {
                                return <option value={item.id}>{item.name}</option>;
                            })
                        }
                    </select>
                    {props.errors && props.errors.subaccount_id &&
                    <FormText className="text-white">{props.errors.subaccount_id}</FormText>
                    }
                </div>
                <div className="col-md-12 form-group">
                    <label htmlFor="name">الملاحظات : </label>
                    <textarea name="description"
                              cols="5"
                              rows="3"
                              value={props.description}
                              className="form-control silver"
                              onChange={(el) => props.onChange(el, props.number)}
                    />
                    {props.errors && props.errors.description &&
                    <FormText className="text-white">{props.errors.description}</FormText>
                    }
                </div>
                <div className="form-group col-md-12">
                    <label className="el-switch" htmlFor={`cashDeskSeller_`+props.id}>
                        <span className="margin-l">الصندوق</span>
                        <input type="checkbox" name="cashDeskSeller" id={`cashDeskSeller_`+props.id} value="1"/>
                        <span className="el-switch-style"/>
                    </label>
                </div>
            </div>
        )
    }

    return (
        <div className={props.type === "receiver" ? "card  active" : "card "}>
            <div className="card-body">
                <div className="form-row">
                    {duplicate()}
                    {render()}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        account: state.account
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAccount: () => dispatch({type: 'SET_ACCOUNT'}),
        clearAccount: () => dispatch({type: 'CLEAR_ACCOUNT'})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Deposit)

