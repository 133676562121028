const avii = {
    "ar": {
        "global": {
            "transfer-receiver": "المصروفه له",
            "transfer-payer": "المصروفه لنا",
            "exchange-payer": "بیع العملات",
            "exchange-receiver": "شرا العملات",
            "cheque-in": "الحواله وارده",
            "cheque-out": "الحواله صادره",
            "cheque": "الحوالات",
            "transfer": "له.علیه",
            "exchange": "الصیرفه",
            "setting": "اعدادات",
            "tracking": "جرد",
            "customer": "جرد",
            "The username or password is incorrect": "خطأ في اسم المستخدم أو كلمة السر",
            "UpdateJarad":" جرد",
            "createJarad":" جرد",
            "deleteJarad":" جرد",
            "update":"update",
            "create":"create",
            "delete":"delete",
            "error":"error",
            "الیومی": "اليومي",
            "السبوعی": "الاسبوعي",
            "الشهری": "الشهري",
            "خانه": "الرئيسية",
            "صندوق": "الصندوق",
            "ليست": "قائمة",
            "ساخت": "انشاء",
            "ساخت جرد": "انشاء جرد",
            "تاریخ ایجاد": "تاريخ الانشاء",
            "المصروفه لنا": "المصروف لنا",
            "المصروفه له": "المصروف له",
            "سال المالی": "السنة المالية",
            "ویرایش": "تعدیل",
            "ذخیره": "حفظ",
            "home":"home",
            "cheque_number":"رقم الحواله",
            "cheque_code":"رمز الحواله",
            "form_date":"من تاریخ",
            "to_date":"الی تاریخ",
            "cheque_currency":"نوع العمل",
            "cheque_price":"مبلغ الحواله",
            "username":"المستخدم",
            "description":"الملاحظات",
            "create-correspond":"create correspond",
            "accounting":"accounting",

        },
        "forms": {
            "username can't be blank": "لايمكن ترك اسم المستخدم فارغا",
            "Please enter a valid username": "یرجی ادخال اسم المستخدم المتاح",
            "username's length must greater than 6": "اسم المستخدم يجب ان يكون اكثر من ستة عناصر",
            "Password can't be blank": "لايمكن ترك كلمة السر فارغا",
            "Password should not contain white spaces": "كلمة السر لايمكن ان تحتوي مسافات",
            "Password's length must between 6 to 16": "كلمة السر يجب ان تتكون من ٦-١٦ عنصر",
            "You are successfully signed in.": "تم الدخول بنجاح",
            "Your password and confirmation password do not match.": "كلمة المرور وكلمة المرور التأكيدية غير متطابقتين.",
            "cheque date can not be empty.": "لا يمكن أن يكون تاريخ الحواله فارغًا.",
            "cheque price can not be empty.": "لا يمكن أن يكون سعر الحواله فارغًا.",
            "cheque reason can not be empty.": "لا يمكن أن يكون السبب الحواله فارغًا.",
            "cheque currency can not be empty.": "لا يمكن أن يكون عملة الحواله فارغًا.",
            "cheque description can not be empty.": "لا يمكن أن يكون وصف الحواله فارغًا.",
            "cheque code can not be empty.": "لا يمكن أن يكون الشفرة الحواله فارغًا.",
            "transfer date can not be empty.": "transfer date can not be empty.",
            "transfer description can not be empty.": "transfer description can not be empty.",
            "transfer currency can not be empty.": "transfer currency can not be empty.",
            "transfer price can not be empty.": "transfer price can not be empty.",
            "transfer account id can not be empty.": "transfer account id can not be empty.",
            "transfer amount can not be empty.": "transfer amount can not be empty.",
            "transfer subAccount can not be empty.": "transfer subAccount can not be empty.",
            "identificationNumber can't be blank": "identificationNumber can't be blank",
            "name can't be blank": "name can't be blank",
            "email can't be blank": "email can't be blank",
            "mobile can't be blank": "mobile can't be blank",
            "sellerAccountId can't be blank": "sellerAccountId can't be blank",
            "salesAmount can't be blank": "salesAmount can't be blank",
            "buyerAccountId can't be blank": "buyerAccountId can't be blank",
            "buyerCurrency can't be blank": "buyerCurrency can't be blank",
            "buyerPrice can't be blank": "buyerPrice  can't be blank",
            "buyerAmount can't be blank": "buyerAmount can't be blank",
            "description can't be blank": "description can't be blank",
            "buyerSubAccountId can't be blank": "buyerSubAccountId can't be blank",
            "sellerSubAccountId can't be blank": "sellerSubAccountId can't be blank",
            "salesPrice can't be blank": "salesPrice can't be blank",
            "sellerCurrency can't be blank": "sellerCurrency can't be blank",
            "the transaction model save successfully": "the transaction model save successfully",
            "the transaction model update successfully": "the transaction model update successfully",
            "error in update transaction model": "error in update transaction model",
            "error in save transaction model": "error in save transaction model",
            "create currency": "تعریف العمله",
            "exchange date can not be empty.": "exchange date can not be empty.",
            "date can't be blank": "date can't be blank",
            "accountId is false": "accountId is false",
            "Updated successfully": "با موفقیت ویرایش شد",
            "created successfully": "با موفقیت ایجاد شد",
            "deleted successfully": "با موفقیت حذف شد شد",
            "error": "خطا",
            "نام": "الاسم",
            "موبایل": "الموبايل",
            "انتخاب کنید": "اختيار",
            "نام کاربری": "اسم المستخدم",
        },
        "pages": {
            "settings": {
                "create user": "تعریف المستخدم",
                "create customer": "تعریف مشتری",
                "create account": "تعریف الحساب",
                "create currency": "تعریف العمله",
                "create subaccount": "تعریف حساب فرعی",
                "create backup": "تعریف مشتری",
                "create logo": "تعریف مشتری",
                "change password": "تعریف مشتری",
                "fiscalyear year": "سال المالی",
                "list user": " المستخدم",
                "list customer": " مشتری",
                "list account": " الحساب",
                "list currency": " العمله",
                "list subaccount": " حساب فرعی",
                "list backup": " مشتری",
                "list logo": " مشتری",
                "list password": " مشتری",
                "list fiscalyear year": "سال المالی",

            },
            "fiscalyear": {
                "create": "create fiscalyear",
                "update": "update fiscalyear ",
                "list": "list"
            }
        }
    },
    "fa": {}
};
export default avii;
