import React, {useState} from "react";
import Logo from "../logo/logo";
import {Button, FormControl, FormGroup, FormText} from 'react-bootstrap';
import Token from "./Token";
import './login.css'
import translate from "../../message/avii";

import FormLabel from "react-bootstrap/FormLabel";
import {isContainWhiteSpace, isEmail, isLength, isEmpty, isUsername} from "../../shared/validator";
import divWithClassName from "react-bootstrap/esm/divWithClassName";
import {BaseUrl} from "../../utilities/StaticProvider";
import {NotificationContainer, NotificationManager} from "react-notifications";
import 'react-notifications/lib/notifications.css';

const Login = (props) => {

    const [state, setState] = useState({
        formData: {}, // Contains login form data
        errors: {}, // Contains login field errors
        formSubmitted: false, // Indicates submit status of login form
        loading: false // Indicates in progress state of login form
    });

    const {token, setToken} = Token();

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        let {formData} = state;
        formData[name] = value;

        setState({
            ...state,
            formData: formData
        });
    };

    const login = (e) => {

        e.preventDefault();

        let errors = validateLoginForm();

        if (errors === true) {
            let options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(state.formData)
            };

            fetch(BaseUrl + "auth/login", options)
                .then(res => res.json())
                .then(
                    (result) => {
                        if (!result.success) {
                            let errors = {};
                            NotificationManager.error(translate.ar.global["The username or password is incorrect"],'مستخدم تسجيل الدخول');
                            setState({
                                ...state,
                                errors: errors,
                                formSubmitted: true
                            });
                        } else {
                            NotificationManager.success('تسجيل الدخول بنجاح','مستخدم تسجيل الدخول');
                            setToken(result.data);
                            window.location.reload();
                        }
                    },
                    (error) => {
                        setState({
                            ...state,
                            loading: true,
                            errors: {error}
                        });
                    }
                );
        } else {
            setState({
                ...state,
                errors: errors,
                formSubmitted: true
            });
        }
    };

    const validateLoginForm = (e) => {

        let errors = {};
        const {formData} = state;

        if (isEmpty(formData.username)) {
            errors.username = translate.ar.forms["username can't be blank"];
        } else if (!isUsername(formData.username)) {
            errors.username = translate.ar.forms["Please enter a valid username"];
        } else if (!isLength(formData.username, {gte: 4, trim: true})) {
            errors.username = translate.ar.forms["username's length must greater than 6"];
        }

        if (isEmpty(formData.password)) {
            errors.password = translate.ar.forms["Password can't be blank"];
        } else if (isContainWhiteSpace(formData.password)) {
            errors.password = translate.ar.forms["Password should not contain white spaces"];
        } else if (!isLength(formData.password, {gte: 6, lte: 16, trim: true})) {
            errors.password = translate.ar.forms["Password's length must between 6 to 16"];
        }
        if (isEmpty(errors)) {
            return true;
        } else {
            return errors;
        }
    };

    return (
        <div className="container_fluid p-0">
            <div id="login">
                <div className="login-section">

                    <div className="login-logo">
                        <Logo width={200} height={200}/>
                    </div>

                    <div className="login-form">
                        <form onSubmit={login}>
                            {state.errors.result &&
                            <div className="alert alert-danger" role="alert">
                                {state.errors.result}
                            </div>
                            }

                            <div className="form-group">
                                <label htmlFor="username">اسم المستخدم</label>
                                <div className="inner-addon right-addon">
                                    <i className="icon icon-user"/>
                                    <input type="text"
                                           name="username"
                                           className="form-control"
                                           onChange={handleInputChange}
                                           required
                                           id="username"/>
                                    {state.errors.username &&
                                    <FormText>{state.errors.username}</FormText>
                                    }
                                </div>

                            </div>

                            <div className="form-group">
                                <label htmlFor="password">کلمه العبور</label>
                                <div className="inner-addon right-addon">
                                    <i className="icon icon-Eye"/>
                                    <input type="password"
                                           name="password"
                                           className="form-control"
                                           onChange={handleInputChange}
                                           required
                                           id="password"/>
                                    {state.errors.password &&
                                    <FormText>{state.errors.password}</FormText>
                                    }
                                </div>

                            </div>


                            <div className="form-group">
                                <div className="checkbox">
                                    <label>
                                        <input type="checkbox" value="remember-me"/>حفظ كلمة السر
                                    </label>
                                </div>
                            </div>


                            <div className="form-group">
                                <button type="submit" className="w-100 btn btn-primary btn-lg btn-block">تسجیل الدخول
                                </button>
                            </div>

                        </form>
                    </div>

                    <div className="login-copyright">
                        <span>2021 All Rights Reserved</span>
                        <span>
                        <a href="#">Privacy Policy</a>
                                and
                                <a href="#">Term Of Service</a>
                                apply
                            </span>
                    </div>
                </div>
                <div className="login-banner"/>
            </div>
            <NotificationContainer/>
        </div>
    );
};


export default Login
