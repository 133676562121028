import { useState } from 'react';

const Token = (props)=> {
    const getToken = () => {
        const tokenString = localStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        return userToken?.access_token
    };

    const [token, setToken] = useState(getToken());

    const saveToken = userToken => {
        localStorage.setItem('token', JSON.stringify(userToken));
        setToken(userToken.token);
    };


    const getUser = ()=>{
        const tokenString = localStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        return userToken?.user
    };


    return {
        setToken: saveToken,
        getToken: getToken,
        getUser: getUser,
        token
    }
};


export default Token
