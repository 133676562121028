import React, {useEffect, useState} from "react";
import List from  "../../list/list"
import moment from "moment";
import Token from "../../auth/Token";
import dataService from "../../../services/data.service";
import Datatable from "../../dataTable/DataTable";
import Breadcrumb from "../../Breadcrumb";
import Parser from "html-react-parser";


const AccountList = () => {
    const config = {
        create: {
            link: './create',
            icon: 'icon-Plus',
            title: 'تعریف حساب'
        }
    };
    const columns = [
        {
            key: 'id',
            title: 'الترتیب',
            value: function (model) {
                return model.id;
            }
        },
        {
            key: 'name',
            title: 'الاسم',
            value: function (model) {
                return model.name;
            }
        },
        {
            key: 'nationalCode',
            title: 'رقم الهویه',
            value: function (model) {
                return model.nationalCode;
            }
        },
        {
            key: 'mobile',
            title: 'الموبايل',
            value: function (model) {
                return model.mobile;
            }
        },
        {
            key: 'description',
            title: 'التفاضیل',
            value: function (model) {
                return model.description;
            }
        },
        {
            key: 'type',
            title: 'نوع الحساب',
            value: function (model) {
                if(model.type===1){
                    return Parser('<span class="badge badge-success">' + 'عام' + '</span>');

                }else{
                    return Parser('<span class="badge badge-danger">' + 'الصندوق' + '</span>');

                }
            }
        },
        {
            key: 'status',
            title: 'الفعال',
            value: function (model) {
                if(model.status==="1"){
                    return Parser('<span class="badge badge-success">' + 'فعال' + '</span>');

                }else{
                    return Parser('<span class="badge badge-danger">' + 'غیرفعال' + '</span>');

                }
            }
        },
        {
            key: 'name',
            title: '',
            value: function (model) {
                return '';
            }
        }
    ];
    const actions = [
        {
            link: "/",
            name: "الرئيسية"
        },
        {
            link: "/setting",
            name: "اعدادات"
        },
        {
            link: '/account/update/{id}',
            icon: 'icon icon-Edit',
            type : 'link'
        },
        {
            link: '/account/delete/{id}',
            icon: 'icon icon-Cancel',
            type : 'link'
        }
    ];
    const  breadcrumb=[
        {
            link: "/",
            name: "الرئيسية"
        },
        {
            link: "/account",
            name: "قائمة"
        }
    ]
    const {token} = Token();
    const ds = new dataService(token, 'accounts');
    return (
        <div class="col-md-12">
            <Breadcrumb data={breadcrumb}/>
            <Datatable entity="accounts"
                       dataService={ds}
                       title = "حساب"
                       columns={columns}
                       actions={actions}
                       config={config}
            />
        </div>
    )


}

export default AccountList
