import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {FormText} from "react-bootstrap";
import Select from 'react-select'
import CurrencyInput from "react-currency-input-field";
import Avatar from "react-avatar-edit";

const ChequeIn = (props) => {
    const customStyles = {
        control: (base, state) => ({
            ...base,
            background: state.isFocused ? "#fff" : "#e8e8e8",
            borderRadius: "8px",
            borderColor: "#e8e8e8",
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                borderColor: state.isFocused ? "red" : "blue"
            }
        }),
        menu: base => ({
            ...base,
            borderRadius: 0,
            marginTop: 0
        }),
        menuList: base => ({
            ...base,
            padding: 0
        })
    };

    const account = useSelector((state) => state.account);
    const [subAccount, setSubAccount] = useState([]);
    const [cash, setCash] = useState(props.cash);
    const [state, setState] = useState({
        preview: null,
        src : null
    });

    let changeAccount = (el) => {
        let state = 0;

        props.accounts.forEach(element => {
            if (element.id === el.value) {
                state = element
            }
        });
        if (state === 0) {
            setSubAccount([])
        } else {
            setSubAccount(state["sub_account"])
        }
    };

    let getCommissionPrice = () => {
        if (props.item.commission_rate === 0)
            return 0;
        else
            return ((props.price * props.item.commission_rate) / 100);
    };

    let getCommissionTotal = () => {
        if (props.commission_rate === 0)
            return props.commission_total;
        else
            return 0;
    };


    let getPrice = () => {
        if (props.commission_rate === 0)
            return +props.price;
        else
            return +((props.price * props.commission_rate) / 100) + +props.price;
    }

    function duplicate() {
        if (props.count === 0) {
            return (
                <button type="button" className="add-button" onClick={() => props.onAddItem(props.number)}>
                    <span className="icon-Plus"/>
                </button>
            )
        } else {
            return (
                <button type="button" className="remove-button" onClick={() => props.onDeleteItem(props.number)}>
                    <span className="icon-Cancel"/>
                </button>
            )
        }
    }

    function render() {
        const labelStyle = {
            "font-family": "Droid Arabic Kufi"
        };
        return (
            <div className="form-row">
                <div className="card-body">
                    {/*customer */}
                    <div className="form-row">
                        <div className="col-md-12 form-group">
                            <label htmlFor="customer_id">اسم المرسل : </label>
                            <Select
                                options={
                                    props.customers.map((item) => {
                                        return {value: item.id, label: item.name}
                                    })
                                }
                                value={
                                    props.customers.map((item) => {
                                        return {value: item.id, label: item.name}
                                    }).find((row) => {
                                        return +row.value === +props.customer_id;
                                    })
                                }
                                styles={customStyles}
                                name="customer_id"
                                className="minimal"
                                onChange={(data) => props.onChange({
                                    target: {
                                        name: 'customer_id',
                                        value: data.value
                                    }
                                }, props.number)}

                            />

                            {props.errors && props.errors.customer_id &&
                            <FormText>{props.errors.customer_id}</FormText>

                            }
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="col-md-12 form-group">
                            <label htmlFor="account_id">حساب الدافع :</label>
                            <Select
                                options={
                                    props.accounts.map((item) => {
                                        return {value: item.id, label: item.name}
                                    })
                                }
                                styles={customStyles}
                                name="account_id"
                                className="minimal"
                                defaultValue={props.allowSelectAccount && account && [{value: account.id, label: account.name}]}
                                value={
                                    props.accounts.map((item) => {
                                        return {value: item.id, label: item.name}
                                    }).find((row) => {
                                        return +row.value === +props.account_id;
                                    })
                                }
                                onChange={(data) => {
                                    props.onChange({
                                        target: {
                                            name: 'account_id',
                                            value: data.value
                                        }
                                    }, props.item.number)
                                    changeAccount(data);
                                }}

                                // onChange={(data) => props.onChange({
                                //     target: {
                                //         name: 'account_id',
                                //         value: data.value
                                //     }
                                // }, props.item.id)}


                            />
                            {props.errors && props.errors.account_id &&
                            <FormText>{props.errors.account_id}</FormText>
                            }
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-12">
                            <label htmlFor="subAccount">فرع الحساب :</label>
                            <Select
                                options={
                                    subAccount.map((item) => {
                                        return {value: item.id, label: item.name}
                                    })
                                }
                                name="subAccount"
                                styles={customStyles}
                                className="minimal"
                                value={
                                    subAccount.map((item) => {
                                        return {value: item.id, label: item.name}
                                    }).find((row) => {
                                        return +row.value === +props.subaccount_id;
                                    })
                                }
                                onChange={(data) => props.onChange({
                                    target: {
                                        name: 'subaccount_id',
                                        value: data.value
                                    }
                                }, props.item.id)}

                            />
                            {props.errors && props.errors.subaccount_id &&
                            <FormText>{props.errors.subaccount_id}</FormText>
                            }
                        </div>
                    </div>



                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label htmlFor="commission_rate">نسبه المعموله :</label>
                            <input type="number"
                                   min="0"
                                   defaultValue={props.commission_rate}
                                   name="commission_rate"
                                   value={props.commission_rate}
                                   id="commission_rate"
                                   className="form-control silver"
                                   onChange={(el) => props.onChange(el, props.item.number)}
                            />
                            {props.errors && props.errors.commission_rate &&
                            <FormText>{props.errors.commission_rate}</FormText>
                            }
                        </div>

                        <div className="col-md-6 form-group">
                            <label htmlFor="commission_price">مبلغ العموله :</label>
                            <CurrencyInput
                                id="commission_price"
                                name="commission_price"
                                className="form-control silver"
                                type="search"
                                defaultValue={0}
                                disabled="disabled"
                                value={getCommissionPrice()}
                                decimalsLimit={2}
                                onValueChange={(value, name) => {
                                    props.onChange({
                                        target: {
                                            name: name,
                                            value: value
                                        }
                                    }, props.item.id);
                                }}
                            />
                            {props.errors && props.errors.commission_price &&
                            <FormText>{props.errors.commission_price}</FormText>
                            }
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="col-md-12 form-group">
                            <label htmlFor="price">مبلغ المطلوب :</label>
                            <CurrencyInput
                                id="price"
                                name="price"
                                className="form-control silver"
                                type="search"
                                defaultValue={0}
                                value={getPrice()}
                                decimalsLimit={2}
                                onValueChange={(value, name) => {
                                    props.onChange({
                                        target: {
                                            name: name,
                                            value: value
                                        }
                                    }, props.item.number);
                                }}
                            />
                        </div>
                    </div>



                    <div className="form-row">
                        <div className="form-group col-md-12">
                            <label htmlFor="commission_total">العموله دینار :</label>
                            <CurrencyInput
                                id="commission_total"
                                name="commission_total"
                                className="form-control silver"
                                type="search"
                                defaultValue={props.commission_total}
                                value={getCommissionTotal()}
                                decimalsLimit={2}
                                onValueChange={(value, name) => {
                                    props.onChange({
                                        target: {
                                            name: name,
                                            value: value
                                        }
                                    }, props.item.id);
                                }}
                            />
                            {props.errors && props.errors.commission_total &&
                            <FormText>{props.errors.commission_total}</FormText>
                            }
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-12">
                            <label className="el-switch el-switch-red" htmlFor={'cash_'+props.number}>
                                <span className="margin-l">الصندوق</span>
                                <input type="checkbox"
                                       name="cash"
                                       id={'cash_'+props.number}
                                       checked={props.cash}
                                       onChange={(value, name) => {
                                           let cashed = !cash;
                                           setCash(cashed);
                                           props.onChange({
                                               target: {
                                                   name: 'cash',
                                                   value: cashed ? 1 : 0
                                               }
                                           }, props.number);
                                       }}
                                />
                                <span className="el-switch-style"/>
                            </label>
                        </div>
                    </div>

                    <div className="form-group col-md-12 center">

                        <Avatar
                            height={100}
                            onImageLoad = {(image)=>{

                            }}
                            cropRadius={20}
                            label="تصویر"
                            labelStyle={labelStyle}
                            lineWidth={0}
                            minCropRadius={100}
                            src={state.image}
                        />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={props.type === "receiver" ? "card" : "card"}>
            {duplicate()}
            {render()}
        </div>

    );
};

export default ChequeIn
