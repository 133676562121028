import React, {useEffect, useState} from 'react';
import {Axios as axios} from "axios";
import {Link} from "react-router-dom";
import da from "moment/dist/locale/da";
import {ShimmerTable} from "react-shimmer-effects";
import Parser from 'html-react-parser';
import {toJSON} from "moment/src/lib/moment/to-type";
import './dataTable.css'
import {useSelector} from "react-redux";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Select from 'react-select';


export default function Datatable(props) {
    const account = useSelector((state) => state.account);

    const [state, setState] = useState({
            entities: {
                data: [],
                meta: {
                    current_page: 1,
                    from: 1,
                    last_page: 1,
                    per_page: 20,
                    to: 1,
                    total: 1,
                },
            },
            first_page: 1,
            current_page: 1,
            sorted_column: props.columns[0].key,
            offset: 4,
            order: 'desc',
            filters: {
                options: [],
                title: props.title
            },
            q: '',
            loader: true
        }
    );

    function deleteHandler(id) {
        props.dataService.delete(id);
        NotificationManager.success('item delete successfully', 'delete item');
        fetchEntities();
        return true;
    }

    function fetchAllEntities() {
        return new Promise((resolve, reject) => {
            setState({...state, loader: true})
            return props.dataService.getAll({
                all: 'all'
            })
                .then(response => {
                    let data = response.data.data.map(entity => {
                        state.filters.options.push({
                            label: entity.name ? entity.name  : "",
                            value: entity.id
                        });
                    });
                    resolve(data);
                })
                .catch(error => {
                    console.error(error);
                    throw error;
                });
        });
    }

    function fetchEntities() {
        return new Promise((resolve, reject) => {
            setState({...state, loader: true})
            return props.dataService.getAll({
                page: state.current_page,
                column: state.sorted_column,
                order: state.order,
                q: state.q,
                params: props.params,
                per_page: state.entities.meta.per_page
            })
                .then(response => {
                    let data = response.data.data.map(entity => {
                        return props.columns.map(item => item.key)
                            .reduce((a, e) => (a[e] = props.columns.find(item => item.key === e).value(entity), a), {});
                    });

                    setState({
                        ...state,
                        entities: {...state.entities, 'data': data, 'meta': response.data.meta},
                        loader: false
                    });
                    resolve(data);
                })
                .catch(error => {
                    console.error(error);
                    throw error;
                });
        });

    }

    function changePage(pageNumber) {
        state.current_page = pageNumber;
        fetchEntities();
    }

    function columnHead(value) {
        return value.split('_').join(' ').toUpperCase()
    }

    function pagesNumbers() {
        if (!state.entities.meta.to) {
            return [];
        }
        let from = state.entities.meta.current_page - state.offset;
        if (from < 1) {
            from = 1;
        }
        let to = from + (state.offset * 2);
        if (to >= state.entities.meta.last_page) {
            to = state.entities.meta.last_page;
        }
        let pagesArray = [];
        for (let page = from; page <= to; page++) {
            pagesArray.push(page);
        }
        return pagesArray;
    }

    useEffect(async () => {
        fetchEntities()
            .then(result => {
                if (props.onSelect) {
                    !account && result[0] && props.onSelect(result[0].id);
                    account && props.onSelect(account.id);
                }
            });
        await fetchAllEntities();
    }, []);


    function tableHeads() {
        let icon;
        if (state.order === 'asc') {
            icon = <i className="fas fa-arrow-up"/>;
        } else {
            icon = <i className="fas fa-arrow-down"/>;
        }
        return props.columns.map(column => {
            return <th className={column.hidden ? "table-head" : "table-head"} key={column.key}
                       onClick={() => sortByColumn(column.key)}>
                {columnHead(column.title)}
                {column.key === state.sorted_column && icon}
            </th>
        });
    }

    function changeQuery(event) {
        setState({...state, q: event.label});
    }

    function filter(event) {
        event.preventDefault();
        fetchEntities();
    }

    function list() {
        if (state.entities.data.length) {
            return state.entities.data.map(model => {

                return <tr key={model.id}
                           className={account && account.id === model.id ? 'active' : ''}
                           onClick={event => props.onSelect && props.onSelect(model.id)}
                           onDoubleClick={event => props.onDoubleClick(model.id)}
                >
                    {Object.keys(model).map(key => <td key={key}>{model[key]}</td>)}
                    <td className="actions">{

                        props.actions.map(item => {
                            let link = item.link.replace(/{id}/i, model.id)
                            if (item.type === 'link') {
                                return (
                                    <Link to={link} title={item.title}>
                                        <span className={item.icon}/>
                                    </Link>
                                )
                            } else {
                                return (<span className={item.icon} onClick={(event) => {
                                    if (window.confirm('Are you sure to delete this record?')) {
                                        deleteHandler(model.id);
                                    }
                                }
                                }/>)

                            }


                        })

                    }</td>

                </tr>
            })
        } else {
            return <tr>
                <td colSpan={props.columns.length} className="text-center">No Records Found.</td>
            </tr>
        }
    }

    function sortByColumn(column) {
        if (column === state.sorted_column) {
            state.order === 'asc' ? setState({...state, order: 'desc', current_page: state.first_page}, () => {
                fetchEntities()
            }) : setState({...state, order: 'asc'}, () => {
                fetchEntities()
            });
        } else {
            setState({...state, sorted_column: column, order: 'asc', current_page: state.first_page}, () => {
                fetchEntities()
            });
        }
    }

    function renderPagination() {
        return (
            <div className="col-md-12">
                {(state.entities.data && state.entities.data.length > 0) &&
                <nav>
                    <div className="customer-pagination pagination">

                        <button disabled={1 === state.entities.meta.current_page}
                                onClick={() => {
                                    changePage(state.entities.meta.current_page - 1)
                                }}
                                className={1 === state.entities.meta.current_page ? 'next' : 'next active'}>
                            <span className="icon icon-Arrow pink reverse"/>
                        </button>

                        <div className="indexes">
                            {Array(state.entities.meta.last_page <= 5 ? state.entities.meta.last_page : 5).fill(0).map((item, index) => {
                                index = index + state.entities.meta.current_page - 1;
                                return <button onClick={() => changePage(index + 1)}
                                               className={state.entities.meta.current_page === index + 1 ? 'index active' : 'index'}>{index + 1}</button>
                            })}
                        </div>

                        <button
                            disabled={state.entities.meta.last_page === state.entities.meta.current_page}
                            onClick={() => {
                                changePage(state.entities.meta.current_page + 1)
                            }}
                            className={state.entities.meta.last_page === state.entities.meta.current_page ? 'prev' : 'prev active'}>
                            <span className="icon icon-Arrow"/>
                        </button>
                    </div>
                </nav>
                }
            </div>
        );
    }

    function renderFilters() {
        const customStyles = {
            control: (base, state) => ({
                ...base,
                background: state.isFocused ? "#fff" : "#e8e8e8",
                borderRadius: "8px",
                borderColor: "#e8e8e8",
                boxShadow: state.isFocused ? null : null,
                "&:hover": {
                    borderColor: state.isFocused ? "red" : "blue"
                }
            }),
            menu: base => ({
                ...base,
                borderRadius: 0,
                marginTop: 0
            }),
            menuList: base => ({
                ...base,
                padding: 0
            })
        };
        return (
            <div className="col-md-12">
                <form className="datatable-filterbox" onSubmit={filter}>
                    <div className="title">{state.filters.title} :</div>
                    <div className="input">
                        <Select
                            name="name"
                            id="name"
                            placeholder={state.filters.title}
                            className="silver"
                            options={state.filters.options}
                            onChange={event => changeQuery(event)}
                            styles={customStyles}
                        />
                    </div>
                    <div className="button">
                        <button className="mini-button" type="submit"><span className="icon-Search"/></button>
                    </div>
                </form>
            </div>
        );
    }


    function render() {
        return (
            <div className="col-md-12">
                <div className="datatable">
                    {
                        props.config.create && <Link to={props.config.create.link} className="datatable-header">

                            <button className="btn">
                                <span className={props.config.create.icon}/>
                            </button>
                            <h5> {props.config.create.title}</h5>


                        </Link>
                    }
                    {state.loader && <ShimmerTable row={4}/>}
                    {!state.loader && <table className={props.tableClass ? props.tableClass : "table"}>
                        <thead>
                        <tr>
                            {tableHeads()}
                        </tr>
                        </thead>
                        <tbody>{list()}</tbody>
                    </table>}
                </div>
            </div>
        );
    }


    return (
        <div className="form-row">
            {renderFilters()}
            {render()}
            {renderPagination()}
        </div>
    );
}
