import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import translate from "../../message/avii";
import {NotificationManager} from "react-notifications";
import Breadcrumb from "../Breadcrumb";
import Button from "../button/Button";
import dataService from "../../services/data.service";
import Token from "../auth/Token";
import {useNavigate} from "react-router";
import './jaradForm.css'
import { BaseUrl } from "../../utilities/StaticProvider";
import CurrencyInput from "react-currency-input-field";


const JardForm = () => {
    const [jard, setJard] = useState([]);
    const [state, setState] = useState([]);
    const [currencyOfficial, setCurrencyOfficial] = useState({
        "name": "",
        "salesPrice": 0
    });

    let {Id} = useParams()
    const loadData = async () => {
        const response = await fetch(Id ? BaseUrl+'jard/' + Id : BaseUrl+'jard/create');
        const dataJson = await response.json();
        let object = {
            currencies: {},
            description:dataJson.data.description?dataJson.data.description:""
        };
        dataJson.data.currencies.forEach(item => {
            object.currencies[item.currencyInformation.id] = {
                currencyInformation: item.currencyInformation,
                accountsAmount: item.accountsAmount,
                cheque: item.cheque,
                sum: item.sum,
                newSum: item.newSum ? item.newSum : item.sum,
                sumAll: Id? item.sumAll: handleAmountAllFirst(item,dataJson.data.currencies),
                other: item.other ? item.other : 0
            }
        })
        setState(object);
        setCurrencyOfficial(dataJson.data.currencyOfficial);
        setJard(dataJson.data.currencies);
    };
    useEffect(async () => {
        loadData()
    }, []);

    const {token} = Token();
    const navigate = useNavigate();
    const modelDataService = new dataService(token, 'jard');

    function save(event) {
        event.preventDefault();
        let currencies = []


        Object.entries(state.currencies).forEach(([key, value]) => {
            currencies.push(value)
        })

        const formData = {
            data: currencies,
            description:state.description
        };


        if (Id) {
            modelDataService.update(Id, formData)
                .then(response => {

                    NotificationManager.success(translate.ar.forms["Updated successfully"], translate.ar.global["UpdateJarad"]);
                    navigate('/jard')
                })
                .catch(e => {
                    NotificationManager.error(translate.ar.forms["error"], translate.ar.global["UpdateJarad"]);
                });
        } else {
            modelDataService.create(formData)
                .then(response => {

                    NotificationManager.success(translate.ar.forms["created successfully"], translate.ar.global["createJarad"]);
                    navigate('/jard')
                    // navigate('/')
                })
                .catch(e => {
                    NotificationManager.error(translate.ar.forms["error"], translate.ar.global["createJarad"]);                    // navigate('/')
                });
        }


    }

    function handleChange(event, currencyId) {

        let data = state.currencies

        Object.entries(data).forEach(([key,value])=>{
            if (key == currencyId){
                data[key].newSum = data[key].sum + parseInt(event.target.value)
                data[key].other = parseInt(event.target.value)
                data[key].sumAll=handleAmountAll(key,data)
            }else{
                data[key].sumAll=handleAmountAll(key,data)
            }

        })

        setState({...state, currencies: data});
    }

    function handleAmountAll(currentCurrencyId,data) {
        let sum=0;
        let currentCurrency=data[currentCurrencyId];
        Object.entries(data).forEach(([key,value])=>{
            if (key !=currentCurrencyId){
                sum=sum+ (value.currencyInformation.salesPrice * value.newSum)/currentCurrency.currencyInformation.salesPrice

            }
        })
        return sum + currentCurrency.newSum;
    }

    function  handleAmountAllFirst(currentCurrency,data){

        let sum=0;
        let currentCurrencyId=currentCurrency.currencyInformation.id;

        data.forEach((value)=>{
            if (value.currencyInformation.id !=currentCurrencyId){
                sum=sum+ ( value.currencyInformation.salesPrice  *  value.sum ) / currentCurrency.currencyInformation.salesPrice
            }
        })
        return sum + currentCurrency.sum;
    }

    function handelDescription(event){
        setState({...state, description: event.target.value});
    }
    const breadcrumb = [{
        link: "/",
        name: "الرئيسية"
    },
        {
            link: "/jard",
            name: "قائمة"
        },
        {
            link: "/jard/create",
            name: "جرد"
        }
    ]
    return (
        <div class="col-md-12">
            <Breadcrumb data={breadcrumb}/>
            <form className="form card">
                <div className="card-body ">
                    <div style={{"margin-bottom": "20px"}} className="col-md-12">
                        <div className="col-md-12 ">
                            <label htmlFor="nationalCode"> جرد </label>
                            <div className="text-left ">
                                <div className="btn "
                                        style={{
                                            "border-radius": "0px 3px 3px 0px",
                                            color: "black",
                                            background: "#eac983"
                                        }}>
                                    {currencyOfficial.name}
                                </div>
                                < div className="btn  " style={
                                    {
                                        "border-radius": "4px 0px 0px 3px",
                                        color: "black",
                                        background: "white",
                                        "margin-right": "-7px",
                                        border: "1px solid #eac983"
                                    }
                                }> {currencyOfficial.salesPrice}
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <tabel className="table table-responsive w-100 d-block d-md-table">
                                <thead>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col">الحسابات</th>
                                    <th scope="col">غیرمستمله</th>
                                    <th scope="col">ادخل يدوي</th>
                                    <th scope="col">المجموع</th>
                                    <th scope="col">الکل</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    jard.map((item) => {
                                        return <tr>
                                            <td>{item.currencyInformation.name}</td>
                                            <td>{item.accountsAmount}</td>
                                            <td>{item.cheque}</td>
                                            <td>
                                                <CurrencyInput
                                                    id="number"
                                                    name="number"
                                                    className="form-control silver"
                                                    decimalsLimit={2}
                                                    onValueChange={(value, name) => handleChange({
                                                        target: {
                                                            value,
                                                            name
                                                        }
                                                    }, item.currencyInformation.id)}
                                                />
                                            </td>
                                            <td>{state.currencies[item.currencyInformation.id].newSum}</td>
                                            <td>{state.currencies[item.currencyInformation.id].sumAll}</td>
                                        </tr>
                                    })
                                }
                                </tbody>

                            </tabel>
                        </div>
                    </div>


                    <div className="col-md-12 form-group">
                        <label htmlFor="exampleInputEmail1">توضیحات</label>
                        <textarea
                            className="inputStyle form-control "
                            rows="5"
                            name="description"
                            defaultValue={state.description}
                            onChange={(el) => {
                                handelDescription(el);
                            }}
                        >

                                    </textarea>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="buttons center">
                                <Button type="submit"
                                        className="green"
                                        handleClick={save}
                                >

                                    <span className="icon-Save"/>تسجیل
                                </Button>
                                <Button className="blue">
                                    <span className="icon-Cancel"/>الغاء
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default JardForm
