import React, {useEffect, useState} from "react";
import Select from 'react-select';
import axios from 'axios';
import {BaseUrl} from './../../utilities/StaticProvider'
import {useNavigate, useParams} from "react-router-dom";
import {isContainWhiteSpace, isEmpty, isLength, isUsername} from "../../shared/validator";
import translate from "../../message/avii";
import {NotificationManager} from "react-notifications";
import {FormText} from "react-bootstrap";
import Breadcrumb from "../Breadcrumb";


const AccountForm = () => {

    let {id} = useParams();
    const navigate = useNavigate();

    const [state, setState] = useState({
        formData: {
            name: null,
            mobile: null,
            creatorId: null,
            nationalCode: null,
            description: "",
            email: null,
            status: '1',
            type: 1
        },
        errors: {},
        formSubmitted: false,
        loading: false,
        accountTypeOptions: [],
        isChecked: true
    });

    let handleCheckBox = event => {
        setState({
            ...state,
            isChecked: event.target.checked
        });
        state.formData.status = event.target.checked ? '1' : '2';
    };

    const accountTypeOptions = [
        {label: "عام", value: 1},
        {label: "الصندوق", value: 2}
    ];

    useEffect(async () => {
        if (id) {
            await getAccount(id);
        }

    }, []);


    async function getAccount(id) {
        let result = await axios.get(BaseUrl + 'accounts/' + id);
        let account = result.data.data;


        let {formData} = state;

        setState({
            ...state, formData: {
                ...formData,
                id: account.id,
                cardNumber: account.cardNumber,
                email: account.email,
                mobile: account.mobile,
                name: account.name,
                currencies: account.currencies,
                nationalCode: account.nationalCode,
                type: account.type,
                description: account.description,
                status: account.status === '1',
            },
            isChecked: account.status === '1'
        });
    }

    let handelCash = (event) => {
        setState({
            ...state, formData: {
                ...state.formData, type: event.value
            }
        })
    };


    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        let {formData} = state;
        formData[name] = value;

        setState({
            ...state,
            formData: formData
        });
    };

    const validateLoginForm = (e) => {

        let errors = {};
        const {formData} = state;

        if (isEmpty(formData.name)) {
            errors.name = translate.ar.forms["name can't be blank"] ?? 'error!';
        }

        if (isEmpty(errors)) {
            return true;
        } else {
            return errors;
        }

    };

    function createAccount(e) {
        e.preventDefault();
        let errors = validateLoginForm();
        if (errors === true) {
            const {formData} = state;

            if (!id) {
                let data = {
                    name: formData.name,
                    mobile: formData.mobile,
                    creatorId: 1,
                    nationalCode: formData.nationalCode,
                    description: formData.description,
                    email: formData.email,
                    status: formData.status ?? 1,
                    currencies: formData.currencies,
                    type: formData.type,
                };

                axios({
                    method: 'post',
                    url: BaseUrl + 'accounts',
                    data: data
                }).then((response) => {
                    NotificationManager.success('تسجيل الدخول بنجاح', 'مستخدم تسجيل الدخول');
                    navigate("/account");
                }, (error) => {
                    Object.entries(error.response.data.data).forEach(item => {
                        NotificationManager.error(JSON.stringify(item[1][0]));
                    });
                });

            } else {
                let data = {
                    id: formData.id,
                    name: formData.name,
                    mobile: formData.mobile,
                    creatorId: 1,
                    nationalCode: formData.nationalCode,
                    description: formData.description,
                    email: formData.email,
                    status: formData.status ?? 1,
                    currencies: formData.currencies,
                    type: formData.type,
                };

                axios({
                    method: 'put',
                    url: BaseUrl + 'accounts/' + id,
                    data: data
                }).then((response) => {
                    NotificationManager.success('تسجيل الدخول بنجاح', 'مستخدم تسجيل الدخول');
                    navigate("/account");
                }, (error) => {
                    NotificationManager.error(translate.ar.global["create account error."], 'create account error.');
                });
            }

        } else {
            setState({
                ...state,
                errors: errors,
                formSubmitted: true
            });
        }
    };

    function render() {
        const customStyles = {
            control: (base, state) => ({
                ...base,
                background: state.isFocused ? "#fff" : "#e8e8e8",
                borderRadius: "8px",
                borderColor: "#e8e8e8",
                boxShadow: state.isFocused ? null : null,
                "&:hover": {
                    borderColor: state.isFocused ? "red" : "blue"
                }
            }),
            menu: base => ({
                ...base,
                borderRadius: 0,
                marginTop: 0
            }),
            menuList: base => ({
                ...base,
                padding: 0
            })
        };
        const {formData} = state;
        return (
            <div className="card-body">
                <div className="form-row">
                    <div className="col-md-6 form-group">
                        <label htmlFor="nationalCode">رقم الهویه</label>
                        <input name="nationalCode"
                               id="nationalCode"
                               value={state.formData.nationalCode}
                               type="text"
                               onChange={handleInputChange}
                               className="form-control silver"/>
                        {state.errors.nationalCode &&
                        <FormText>{state.errors.nationalCode}</FormText>
                        }
                    </div>
                    <div className="col-md-6 form-group">
                        <label htmlFor="name">اسم الحساب</label>
                        <input name="name"
                               id="name"
                               type="text"
                               onChange={handleInputChange}
                               defaultValue={formData.name}
                               className="form-control silver"/>
                        {state.errors.name &&
                        <FormText>{state.errors.name}</FormText>
                        }
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-6 form-group">
                        <label htmlFor="email">الایمیل</label>
                        <input
                            type="text"
                            className="silver form-control"
                            onChange={handleInputChange}
                            name="email"
                            id="email"
                            defaultValue={formData.email}/>
                        {state.errors.email &&
                        <FormText>{state.errors.email}</FormText>
                        }
                    </div>
                    <div className="col-md-6 form-group">
                        <label htmlFor="mobile">الموبایل</label>
                        <input
                            type="text"
                            className="silver form-control"
                            id="mobile"
                            name="mobile"
                            onChange={handleInputChange}
                            defaultValue={formData.mobile}/>
                        {state.errors.mobile &&
                        <FormText>{state.errors.mobile}</FormText>
                        }
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-12 form-group">
                        <label htmlFor="currencies"> نوع الحساب:</label>
                        <Select
                            name="type"
                            id="type"
                            value={accountTypeOptions.find((item) => item.value === state.formData.type)}
                            className="silver"
                            onChange={handelCash}
                            options={accountTypeOptions}
                            styles={customStyles}
                        />
                        {state.errors.currencies &&
                        <FormText>{state.errors.currencies}</FormText>
                        }
                    </div>
                </div>

                <div className="form-row">
                    <div className="col-md-12 form-group">
                        <label htmlFor="exampleInputEmail1">التفاضیل</label>
                        <textarea
                            name="description"
                            id="description"
                            onChange={handleInputChange}
                            cols="10"
                            rows="5"
                            className="silver form-control"
                            value={state.formData.description}
                        />
                        {state.errors.description &&
                        <FormText>{state.errors.description}</FormText>
                        }
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-12">
                        <label className="el-switch" htmlFor="status">
                            <span className="margin-l">الفعال</span>
                            <input type="checkbox"
                                   name="status"
                                   id="status"
                                   checked={state.isChecked}
                                   onChange={handleCheckBox}
                            />
                            <span className="el-switch-style"/>
                        </label>
                    </div>
                    <div className="col-md-12 form-group btns-group">
                        <button type="submit" className="btn btn-sm btn-success">تسجیل</button>
                        <button type="cancel" className="btn btn-sm btn-danger">الغا</button>
                    </div>
                    {state.errors.error &&
                    <FormText>{state.errors.error}</FormText>
                    }
                </div>
            </div>
        )
    }

    const breadcrumb = [
        {
            link: "/",
            name: "الرئيسية"
        },
        {
            link: "/setting",
            name: "اعدادات"
        },
        {
            link: "/account",
            name: "قائمة"
        },
        {
            link: "/account/create",
            name: "انشاء"
        }
    ]
    return (
        <div>
            <Breadcrumb data={breadcrumb}/>
            <div className="form-box col-md-6">
                <form className=" form card" onSubmit={(el) => createAccount(el)}>
                    {render()}
                </form>
            </div>
        </div>
    )


}

export default AccountForm
