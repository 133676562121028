import React, {useEffect, useState} from "react";
import Datatable from "../dataTable/DataTable";
import dataService from "../../services/data.service";
import Token from "../auth/Token";
import Parser from "html-react-parser";
import moment from "moment";
import AccountingDataTable from "../dataTable/AccountingDataTable";
import Breadcrumb from "../Breadcrumb";

const Accounting = (props) => {

    const config = {
        export: {
            link: './export',
            icon: 'icon-Save',
            title: ''
        }
    };
    const columns = [
        {
            key: 'id',
            title: 'الترتیب',
            value: function (model) {
                return model.id;
            }
        },
        {
            key: 'customer_name',
            title: 'توضیحات',
            value: function (model) {
              let a="";
              if (model.customer_name) {
                a=model.customer_name
              }
              if (model.entity_type === 3) {
                if (model.received === 1) {
                   a= a+" / "+"دریافت شده"
                }else{
                  a= a+"/"+"دریافت نشده"

                }

              }
                // a= a+" "+model.entity_type === 3?(model.received === 1 ?"دریافت شده":"دریافت نشده"):"";


                return a;
            }
        },


        {
            key: 'date',
            title: 'التاریخ/الساعة',
            value: function (model) {
                return moment(model.created_at).format("YYYY/MM/DD hh:mm:ss");
            }
        },
        {
            key: 'type',
            title: 'النوع',
            value: function (model) {
                let types = {
                    1: 'لنا',
                    2: 'له',
                    3: 'حواله وارده',
                    4: 'حواله صادره',
                    5: 'البیع',
                    6: 'الشرا',
                    7: 'مطبقه',
                };
                return Parser('<span class="badge badge-success">' + types[model.entity_type] + '</span>');
            }
        },
        {
            key: 'number',
            title: 'الرقم',
            value: function (model) {
                return model.number;
            }
        }
    ];
    const actions = [];
    const {token} = Token();
    const ds = new dataService(token, 'transactions');

    const  breadcrumb=[
            {
                link: "/",
                name: "الرئيسية"
            },
        {
            link: "/accounting",
            name: "کشف الحساب"
        },

        ]



    return (
        <>
            <Breadcrumb data={breadcrumb}/>
            <AccountingDataTable entity="transaction"
                                 dataService={ds}
                                 columns={columns}
                                 actions={actions}
                                 config={config}/>
        </>
    )
};

export default Accounting
