import React, {useEffect, useState} from "react";
import Datatable from "../dataTable/DataTable";
import dataService from "../../services/data.service";
import Token from "../auth/Token";
import Parser from "html-react-parser";
import moment from "moment";
import AccountingDataTable from "../dataTable/AccountingDataTable";
import Breadcrumb from "../Breadcrumb";
import ChequeDataTable from "./chequeDataTable";

const ChequeList = (props) => {

    const config = {
        export: {
            link: './export',
            icon: 'icon-Save',
            title: ''
        }
    };
    const columns = [
        {
            key: 'number',
            title: 'الرقم الحواله',
            value: function (model) {
                return model.number;
            }
        },
        {
            key: 'date',
            title: 'تاریخ الحواله',
            value: function (model) {
                return moment(model.date).format("YYYY/MM/DD hh:mm:ss");
            }
        },
        {
            key: 'customer1',
            title: 'اسم المرسل',
            value: function (model) {
                return model?.in?.customer?.name;
            }
        },
        {
            key: 'customer2',
            title: 'اسم المستفیذ',
            value: function (model) {
                return model?.out?.customer?.name;
            }
        },
        {
            key: 'currency_id',
            title: 'العمله',
            value: function (model) {
                return model?.currency?.name;
            }
        },
        {
            key: 'price',
            title: 'مبلغ الحواله',
            value: function (model) {
                return model.price;
            }
        },
        {
            key: 'account1',
            title: 'الحساب الدافع',
            value: function (model) {
                return model?.in?.account?.name;
            }
        },
        {
            key: 'account2',
            title: 'الحساب المستلم',
            value: function (model) {
                return model?.out?.account?.name;
            }
        },
        {
            key: 'description',
            title: 'الملاحظات',
            value: function (model) {
                return model?.description;
            }
        },
        {
            key: 'code',
            title: 'الرمز الحواله',
            value: function (model) {
                return model?.code;
            }
        },
        {
            key: 'user',
            title: 'المستخدم',
            value: function (model) {
                return model?.username;
            }
        }

    ];
    const actions = [];
    const {token} = Token();
    const ds = new dataService(token, 'cheque');

    const breadcrumb = [
        {
            link: "/",
            name: "خانه"
        },
        {
            link: props.type === "deposit" ? "/transfer/list/deposit" : "/transfer/list/withdraw",
            name: props.type === "deposit" ? "قائمة المصروف له" : "قائمة المصروف لنا"
        },

    ]


    return (
        <>
            <Breadcrumb data={breadcrumb}/>
            <ChequeDataTable
                entity="transaction"
                type={props.type}
                dataService={ds}
                columns={columns}
                actions={actions}
                config={config}/>
        </>
    )
};

export default ChequeList
