import React, {useContext, useReducer, useEffect, useState} from "react";
import Datatable from "../dataTable/DataTable";
import Token from "../auth/Token";
import dataService from "../../services/data.service";
import Parser from "html-react-parser";
import connect from "react-redux/es/connect/connect";
import {useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import avii from "../../message/avii";

const Dashboard = (props) => {
    const [state, setState] = useState({
        currencies: [],
        account: {}
    });
    const navigate = useNavigate();

    const account = useSelector((state) => state.account);


    const {token} = Token();
    const ds = new dataService(token, 'accounts');

    const config = {
        create: {
            link: 'account/create',
            icon: 'icon-Plus',
            title: 'تعریف الحساب'
        }
    };
    const columns = [
        {
            key: 'id',
            hidden: true,
            title: 'الترتیب',
            value: function (model) {
                return model.id;
            }
        },
        {
            key: 'name',
            title: 'اسم الحساب',
            value: function (model) {
                return Parser(
                    `<div class="account-info">
                                <div className="customer-avatar">
                                    <img src="./assets/images/man.png" alt="">
                                </div>
                                <div className="account-name"> ${model.name}</div>
                            </div>
                        `
                );
            }
        },
        {
            key: 'name',
            title: '',
            value: function (model) {
                return '';
            }
        }
    ];
    const actions = [


        {
            link: '/exchange/seller',
            type: 'link',
            title : avii.ar.global["exchange-receiver"],
            icon: 'icon icon-Shera-Amalat'
        },
        {
            link: '/exchange/buyer',
            type: 'link',
            title : avii.ar.global["exchange-payer"],
            icon: 'icon icon-Beye-Amalat'
        },
        {
            link: '/cheque-in',
            type: 'link',
            title : avii.ar.global["cheque-in"],
            icon: 'icon icon-Ahvalate-Varede'
        },
        {
            link: '/cheque-out',
            title : avii.ar.global["cheque-out"],
            type: 'link',
            icon: 'icon icon-Ahvalate-SAdere'
        },
        {
            link: '/transfer/withdraw',
            title : avii.ar.global["transfer-payer"],
            type: 'link',
            icon: 'icon icon-Lana'
        },
        {
            link: '/transfer/deposit',
            title : avii.ar.global["transfer-receiver"],
            type: 'link',
            icon: 'icon icon-Almasrof-Leh'
        },
        {
            link: '/correspond/create',
            title : avii.ar.global["create-correspond"],
            type: 'link',
            icon: 'icon icon-Motabeghat'
        },
        {
            link: '/accounting',
            title : avii.ar.global["accounting"],
            type: 'link',
            icon: 'icon icon-Kashf-Hesab'
        },


    ];


    const onSelect = async (accountId) => {
        ds.get(accountId)
            .then(response => {
                let currencies = response?.data?.data?.currencies;
                setState({...state, currencies: currencies ? currencies : [], account: response.data.data});
                props.setAccount(response.data.data);
            })
            .catch(error => {
                console.error(error);
            });
    };
    const onDoubleClick = async (accountId) => {
        ds.get(accountId)
            .then(response => {
                props.setAccount(response.data.data);
                navigate("/accounting")
            })
            .catch(error => {
                console.error(error);
            });
    };

    return (
        <div className="form-row">
            <div className="col-md-8 col">
                <Datatable entity="users"
                           dataService={ds}
                           title="حساب"
                           columns={columns}
                           onSelect={onSelect}
                           onDoubleClick={onDoubleClick}
                           tableClass="table dashboard-table"
                           actions={actions}
                           config={config}/>
            </div>

            <div className="col-md-4 col">
                <div className="form-row">
                    <div className="col-md-12">
                        <div className="my-1 p-3 box-shadow" id="customer-info">
                            <div className="form-row">
                                <div className="col-md-12">
                                    <div className="customer-header">
                                        <div className="customer-avatar">
                                            <img src="./assets/images/man.png" alt=""/>
                                        </div>
                                        <div className="customer-name">
                                            <span>{account && account.name}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="customer-currencies my-3">
                                        <div className="form-row">
                                            {
                                                account && account.wallet.map(item => {
                                                    return (
                                                        item.currency && <div className="col-md-6 col-12">
                                                            <div className="customer-currency">
                                                                <div
                                                                    className="currency-price">{item.balance}</div>
                                                                <div
                                                                    className="currency-name">{item.currency.name}</div>

                                                                <div className="currency-icon dinar">
                                                                    <span className="icon icon-MOney-1"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <Link to="/accounting">
                            <div className="my-1 box-shadow big-button green">
                                <div className="icon">
                                    <span className="icon-Kashf-Hesab"/>
                                </div>
                                <div className="title">
                                    کشف حساب
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-12">
                        <div className="my-1 box-shadow link-box">
                            <div className="title">المصروف</div>
                            <div className="links">
                                <Link to="/transfer/deposit"  className="link">
                                    <div className="text">له</div>
                                    <div className="icon">
                                        <span className="icon icon-Almasrof-Leh yellow"/>
                                    </div>
                                </Link>
                                <Link to="/transfer/withdraw"  className="link">
                                    <div className="text">لنا</div>
                                    <div className="icon">
                                        <span className="icon icon-Lana yellow"/>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="my-1 box-shadow link-box">
                            <div className="title">حواله</div>
                            <div className="links">
                                <Link to="/cheque-in"  className="link">
                                    <div className="text">وارده</div>
                                    <div className="icon">
                                        <span className="icon icon-Ahvalate-SAdere yellow"/>
                                    </div>
                                </Link>
                                <Link to="/cheque-out"  className="link">
                                    <div className="text">صادره</div>
                                    <div className="icon">
                                        <span className="icon icon-Ahvalate-Varede yellow"/>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="my-1 box-shadow link-box">
                            <div className="title">عملات</div>
                            <div className="links">

                                <Link to="/exchange/buyer"  className="link">
                                    <div className="text">بیع</div>
                                    <div className="icon">
                                        <span className="icon icon-Beye-Amalat yellow"/>
                                    </div>
                                </Link>

                                <Link to="/exchange/seller"  className="link">
                                    <div className="text">شرا</div>
                                    <div className="icon">
                                        <span className="icon icon-Shera-Amalat yellow"/>
                                    </div>
                                </Link>

                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <Link to="/taslim">
                            <div className="my-1 box-shadow big-button pink">
                                    <span className="icon icon-Taslim-Havalat"/>
                                <div className="title">
                                    تسلیم الحوالات
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
};

const mapStateToProps = (state) => {
    return {
        account: state.account
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAccount: (data) => dispatch({type: 'SET_ACCOUNT', payload: data}),
        clearAccount: () => dispatch({type: 'CLEAR_ACCOUNT'})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
