import React, {useContext, useEffect, useReducer, useState} from "react";
import './Cheque.css';
import Button from "../button/Button";
import ChequeIn from "../cheque-item/ChequeIn";
import ChequeOut from "../cheque-item/ChequeOut";
import {FormText} from "react-bootstrap";
import avii from "../../message/avii";
import {isContainWhiteSpace, isEmpty, isLength, isUsername} from "../../shared/validator";
import {v4 as uuidv4} from 'uuid';
import {BaseUrl} from "../../utilities/StaticProvider";
import Token from "../auth/Token";
import DatePicker from "react-datepicker";
import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";
import {NotificationManager} from "react-notifications";
import Breadcrumb from "../Breadcrumb";
import CurrencyInput from "react-currency-input-field";
import Select from 'react-select'
import {useNavigate, useParams} from "react-router";
import Withdraw from "../transfer/withdraw";
import {useSelector} from "react-redux";

const Cheque = (props) => {

    const customStyles = {
        control: (base, state) => ({
            ...base,
            background: state.isFocused ? "#fff" : "#e8e8e8",
            borderRadius: "8px",
            borderColor: "#e8e8e8",
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                borderColor: state.isFocused ? "red" : "blue"
            }
        }),
        menu: base => ({
            ...base,
            borderRadius: 0,
            marginTop: 0
        }),
        menuList: base => ({
            ...base,
            padding: 0
        })
    };
    const account = useSelector((state) => state.account);
    const defaultChequeIn = {
        id: uuidv4(),
        number: Math.floor(100000 + (Math.random() * (1000000 - 100000))),
        cheque_id: null,
        customer_id: null,
        account_id: account && props.mode === 'in' ? account.id : 0,
        subaccount_id: null,
        user_id: null,
        type: 1,
        commission_rate: 0,
        commission_price: 0,
        commission_total: 0,
        commission_type: 1,
        price: 0,
        total: 0,
        received: 1,
        image: null,
        cash: 0,
        description: null
    };
    const defaultChequeOut = {
        id: uuidv4(),
        number: Math.floor(100000 + (Math.random() * (1000000 - 100000))),
        cheque_id: null,
        customer_id: null,
        account_id: account && props.mode === 'out' ? account.id : 0,
        subaccount_id: null,
        user_id: null,
        type: 2,
        commission_rate: 0,
        commission_total: 0,
        commission_price: 0,
        commission_type: 1,
        price: 0,
        total: 0,
        received: 1,
        image: null,
        cash: 0,
        description: null
    };
    const [startDate, setStartDate] = useState(new Date());

    const [currencies, setCurrencies] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [subAccounts, setSubAccounts] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [price, setPrice] = useState(0);
    const [active, setActive] = useState(0);
    let {id} = useParams();

    let [ins, setIns] = useState([defaultChequeIn]);
    let [outs, setOuts] = useState([defaultChequeOut]);

    const {getUser} = Token();
    const [state, setState] = useState({
        formData: {
            user_id: getUser().id,
            number: Math.floor(100000 + (Math.random() * (1000000 - 100000))),
            date: moment(startDate).format("YYYY/MM/DD hh:mm:ss"),
            code: Math.floor(100000 + (Math.random() * (1000000 - 100000))),
            transactions: [],
            status: 0,
            description: "",
            reason: ""
        },
        errors: {},
        formSubmitted: false,
        loading: false
    });
    const navigate = useNavigate();

    const validateLoginForm = (e) => {

        let errors = {};
        const {formData} = state;

        if (isEmpty(formData.date)) {
            errors.date = avii.ar.forms["cheque date can not be empty."];
        }
        if (isEmpty(formData.price)) {
            errors.price = avii.ar.forms["cheque price can not be empty."];
        }
        if (isEmpty(formData.currency_id)) {
            errors.currency_id = avii.ar.forms["cheque currency can not be empty."];
        }
        if (isEmpty(formData.code)) {
            errors.code = avii.ar.forms["cheque code can not be empty."];
        }

        [...ins, ...outs].forEach(item => {
            errors[item.id] = {};
            if (isEmpty(item.account_id) && item.cash === 0) {
                console.log('item is : ', item);
                errors[item.id].account_id = avii.ar.forms["cheque date can not be empty."];
            }
            if (isEmpty(item.price)) {
                errors[item.id].price = avii.ar.forms["cheque date can not be empty."];
            }
            if (Object.keys(errors[item.id]).length === 0) delete errors[item.id]
        });
        if (isEmpty(errors)) {
            return true;
        } else {
            console.error(errors);
            return errors;
        }
    };

    const loadCurrencies = async () => {
        const response = await fetch(BaseUrl + 'currency');
        const currencies = await response.json();
        setCurrencies(currencies.data);
    };
    const loadAccounts = async () => {
        const response = await fetch(BaseUrl + 'account');
        const accounts = await response.json();
        setAccounts(accounts.data);
    };
    const loadSubAccounts = async () => {
        const response = await fetch(BaseUrl + 'subaccount');
        const subaccount = await response.json();
        setSubAccounts(subaccount.data);
    };
    const loadCustomers = async () => {
        const response = await fetch(BaseUrl + 'customer');
        const customers = await response.json();
        setCustomers(customers.data);
    };

    useEffect(() => {
        loadCurrencies();
        loadAccounts();
        loadCustomers();
        loadSubAccounts();

        getModel(id);

    }, []);

    function getModel(id) {

        fetch(BaseUrl + "cheque/" + id)
            .then(res => res.json())
            .then(
                (result) => {
                    if (!result.success) {
                        let errors = {};
                        setState({
                            ...state,
                            errors: errors,
                            formSubmitted: true
                        });
                    } else {
                        updateFormData(result.data);
                    }
                },
                (error) => {
                    setState({
                        ...state,
                        loading: true,
                        errors: {error}
                    });
                }
            );
    }

    function updateFormData(data) {
        return new Promise((resolve, reject) => {
            try {
                let formData = {};

                formData.number = +data.number;
                formData.id = +data.id;
                formData.date = data.date;
                formData.reason = data.reason;
                formData.description = data.description;
                formData.currency_id = +data.currency_id;
                formData.price = +data.price;
                formData.status = +data.status;
                formData.code = +data.code;
                formData.reason = data.reason;

                setActive(+data.status);

                setPrice(data.price);

                ins = [];
                outs = [];

                data.transactions.forEach(item => {
                    let transaction = {
                        id: item.id,
                        type: item.type,
                        number: +item.number,
                        account_id: item.account_id,
                        customer_id: item.customer_id,
                        subaccount_id: item.subaccount_id,
                        description: item.description,
                        commission_rate: item.commission_rate,
                        commission_price: item.commission_price,
                        commission_total: item.commission_total,
                        total: item.total,
                        cash: +item.typeAccount===2 ? 1 : 0 ,
                        price: item.price
                    };

                    if (+transaction.type === 1) {
                        ins.push(transaction);
                    } else {
                        outs.push(transaction);
                    }

                });

                if (ins.length === 0) {
                    ins.push(defaultChequeIn);
                }

                if (outs.length === 0) {
                    outs.push(defaultChequeOut);
                }

                setIns(ins);
                setOuts(outs);

                setState({...state, formData: formData});

                resolve(true);
            } catch (e) {
                reject(e);
            }
        });
    }

    function handleChequeInItemsChange(event, idx) {
        const update = ins.map((item) => {
            if (+idx !== +item.number) return item;
            return {...item, [event.target.name]: event.target.value};
        });
        setIns(update);
    }

    function handleChequeOutItemsChange(event, idx) {
        const update = outs.map((item) => {
            if (+idx !== +item.number) return item;
            return {...item, [event.target.name]: event.target.value};
        });
        setOuts(update);
    }

    function handleAddChequeInItem() {
        ins = ins.concat([defaultChequeIn]);
        ins.forEach(item => {
            item.price = price / ins.length;
            item.commission_price = 0;
            item.commission_rate = 0;
            item.commission_total = 0;
            item.commission_type = 0;
        });
        setIns(ins);
    }

    function handleAddChequeOutItem() {
        outs = outs.concat([defaultChequeOut]);
        outs.forEach(item => {
            item.price = price / outs.length;
            item.commission_price = 0;
            item.commission_rate = 0;
            item.commission_total = 0;
            item.commission_type = 0;
        });
        setOuts(outs);
    }

    function handleRemoveChequeInItem(number) {
        ins = ins.filter(item => number !== item.number);
        setIns(ins);
    }

    function handleRemoveChequeOutItem(number) {
        outs = outs.filter(item => number !== item.number);
        setOuts(outs);
    }

    function handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        let {formData} = state;

        if (name === 'status') {
            formData.status = target.checked ? 1 : 0;
            setActive(+target.checked);
        } else {
            formData[name] = value;
        }

        setState({...state, formData: formData})
    }

    function handleDateChange(date) {
        setState({
            ...state,
            formData: {
                ...state.formData,
                date: moment(date).format("YYYY/MM/DD hh:mm:ss")
            }
        });
        setStartDate(date);
    }

    function updatePrices() {
        let {formData} = state;
        let price = formData.price;

        [...ins, ...outs].forEach(item => {
            item.price = price;
            if (item.commission_rate === 0) {
                item.commission_price = 0;
            } else if (item.commission_rate) {
                item.commission_price = item.price * item.commission_rate;
            }
        });


        setState({...state, formData: formData});
    }

    function handleChangeAmount(value, name) {

        let {formData} = state;
        formData[name] = value;

        setState({...state, formData: formData});
        setPrice(value);

        updatePrices();

    }

    function makeTransaction(item, type) {
        return {
            "id": item.id,
            "customer_id": +item.customer_id,
            "account_id": +item.account_id,
            "subaccount_id": +item.subaccount_id,
            "type": +item.type,
            "commission_type": +item.total === 0 ? 1 : 2,
            "commission_price": +item.commission_rate === 0 ? 0 : Math.floor((+item.price * +item.commission_rate) / 100),
            "commission_rate": +item.commission_rate,
            "commission_total": +item.commission_total,
            "received": item.received,
            "price": +item.commission_rate === 0 ? +item.price : Math.floor((+item.price * +item.commission_rate) / 100) + +item.price,
            "total": +item.total,
            "cash": item.cash,
            "number": item.number,
            "description": item.description,
            "image": item.image
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();

        state.formData.transactions = [];

        let errors = validateLoginForm();
        if (errors === true) {
            if (id) {
                update();
            } else {
                save()
            }
        } else {
            setState({
                ...state,
                errors: errors,
                formSubmitted: true
            });
        }
    }

    async function save() {
        const {formData} = state;
        formData.price = +formData.price;

        if (formData.reason === "") {
            formData.reason = "cheque number : " + formData.number;
        }
        ins.forEach(item => {
            state.formData.transactions.push(makeTransaction(item, "deposit"))
        });
        outs.forEach(item => {
            state.formData.transactions.push(makeTransaction(item, "withdraw"))
        });

        const response = await fetch(BaseUrl + "cheque", {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(formData),
        })
            .then(res => res.json())
            .then(response => {
                if (response.success) {
                    NotificationManager.success('حواله به حواله با موفقیت انجام شد', 'حواله');
                    navigate("/");

                } else {
                    NotificationManager.error('حواله به حواله با موفقیت انجام نشد', 'حواله');
                    let error = response.message
                    setState({
                        ...state,
                        errors: {
                            response: error
                        },
                        formSubmitted: true
                    });

                }

            })
            .catch(error => {
                NotificationManager.error('حواله به حواله با موفقیت انجام نشد', 'حواله');

            });
    }

    async function update() {
        const {formData} = state;
        formData.price = +formData.price;

        if (formData.reason === "") {
            formData.reason = "cheque number : " + formData.number;
        }
        ins.forEach(item => {
            state.formData.transactions.push(makeTransaction(item, "deposit"))
        });
        outs.forEach(item => {
            state.formData.transactions.push(makeTransaction(item, "withdraw"))
        });

        const response = await fetch(BaseUrl + "cheque/" + id, {
            method: "put",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(formData),
        })
            .then(res => res.json())
            .then(response => {
                if (response.success) {
                    NotificationManager.success('حواله به حواله با موفقیت انجام شد', 'حواله');
                    navigate("/");

                } else {
                    NotificationManager.error('حواله به حواله با موفقیت انجام نشد', 'حواله');
                    let error = response.message
                    setState({
                        ...state,
                        errors: {
                            response: error
                        },
                        formSubmitted: true
                    });

                }

            })
            .catch(error => {
                NotificationManager.error('حواله به حواله با موفقیت انجام نشد', 'حواله');

            });
    }

    function renderIns() {
        return (
            ins.map((item, key) => {

                let {errors} = state;
                return (
                    <div className="col-md-4">
                        <div className="form-row">
                            <ChequeIn accounts={accounts}
                                      subaccounts={subAccounts}
                                      customers={customers}
                                      onAddItem={handleAddChequeInItem}
                                      onDeleteItem={handleRemoveChequeInItem}
                                      onChange={handleChequeInItemsChange}
                                      allowSelectAccount={props.mode === 'in'}
                                      errors={errors[item.id]}
                                      item={item}
                                      cash={item.cash === 1 }
                                      id={item.number}
                                      account_id={item.account_id}
                                      customer_id={item.customer_id}
                                      subaccount_id={item.subaccount_id}
                                      commission_rate={item.commission_rate}
                                      commission_price={item.commission_price}
                                      commission_total={item.commission_total}
                                      number={item.number}
                                      price={item.price}
                                      basePrice={price}
                                      total={item.total}
                                      count={key}/>
                        </div>
                    </div>
                )
            })
        )

    }

    function renderOuts() {
        return (
            outs.map((item, key) => {
                let {errors} = state;
                return (
                    <div className="col-md-4">
                        <div className="form-row">
                            <ChequeOut accounts={accounts}
                                       subaccounts={subAccounts}
                                       customers={customers}
                                       onAddItem={handleAddChequeOutItem}
                                       onDeleteItem={handleRemoveChequeOutItem}
                                       allowSelectAccount={props.mode === 'out'}
                                       onChange={handleChequeOutItemsChange}
                                       errors={errors[item.id]}
                                       item={item}
                                       cash={item.cash === 1 }
                                       id={item.number}
                                       number={item.number}
                                       account_id={item.account_id}
                                       customer_id={item.customer_id}
                                       subaccount_id={item.subaccount_id}
                                       commission_rate={item.commission_rate}
                                       commission_price={item.commission_price}
                                       commission_total={item.commission_total}
                                       price={item.price}
                                       total={item.total}
                                       count={key}/>
                        </div>
                    </div>
                )
            })
        )

    }


    function render() {
        return (
            <div className="col-md-4">
                <div className="form-row">
                    <div className="card">
                        <div className="card-body">
                            <div className="form-row">
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="number">رقم الحواله : </label>
                                        <input name="number"
                                               id="number"
                                               value={state.formData.number}
                                               disabled="disabled"
                                               type="text"
                                               className="form-control silver"
                                               onChange={handleChange}
                                        />
                                        {state.errors.id &&
                                        <FormText>{state.errors.id}</FormText>
                                        }
                                    </div>

                                    <div className="form-group col-md-6">
                                        <label htmlFor="date">تاریخ الحواله : </label>
                                        <DatePicker
                                            name="date"
                                            id="date"
                                            className="form-control silver"
                                            dateFormat="yyyy/MM/dd"
                                            value={state.formData.date}
                                            selected={startDate}
                                            strictParsing
                                            closeOnScroll={true}
                                            onChange={(date) => handleDateChange(date)}
                                        />
                                        {state.errors.date &&
                                        <FormText>{state.errors.date}</FormText>
                                        }
                                    </div>

                                    <div className="form-group col-md-12">
                                        <label htmlFor="currency_id">نوع العمل : </label>
                                        <Select
                                            options={
                                                currencies.map((item) => {
                                                    return {value: item.id, label: item.name}
                                                })
                                            }
                                            value={
                                                currencies.map((item) => {
                                                    return {value: item.id, label: item.name}
                                                }).find((row) => {
                                                    return +row.value === +state.formData.currency_id;
                                                })
                                            }
                                            styles={customStyles}
                                            name='currency_id'
                                            className="minimal"
                                            onChange={(data) => handleChange({
                                                target: {
                                                    name: 'currency_id',
                                                    value: data.value
                                                }
                                            })}

                                        />
                                        {state.errors.currency_id &&
                                        <FormText>{state.errors.currency_id}</FormText>
                                        }
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="price">مبلغ الحواله : </label>
                                        <CurrencyInput
                                            id="price"
                                            name="price"
                                            className="form-control silver"
                                            type="search"
                                            defaultValue={price}
                                            value={state.formData.price}
                                            decimalsLimit={2}
                                            onValueChange={(value, name) => {
                                                handleChangeAmount(value, name);
                                            }}
                                        />
                                        {state.errors.price &&
                                        <FormText>{state.errors.price}</FormText>
                                        }
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label htmlFor="code">رمز الحواله : </label>
                                        <input name="code"
                                               id="code"
                                               type="text"
                                               value={state.formData.code}
                                               onChange={handleChange}
                                               className="form-control silver"
                                        />
                                        {state.errors.code &&
                                        <FormText>{state.errors.code}</FormText>
                                        }
                                    </div>

                                    <div className="col-md-12 form-group">
                                        <label htmlFor="reason">سبب الحواله : </label>
                                        <input name="reason"
                                               id="reason"
                                               type="text"
                                               className="form-control silver"
                                               onChange={handleChange}
                                        />
                                        {state.errors.reason &&
                                        <FormText>{state.errors.reason}</FormText>
                                        }
                                    </div>


                                    <div className="col-md-12 form-group">
                                        <label htmlFor="name">الملاحظات : </label>
                                        <textarea name="description"
                                                  onChange={handleChange}
                                                  rows="5"
                                                  value={state.formData.description}
                                                  className="form-control silver"/>
                                        {state.errors.description &&
                                        <FormText>{state.errors.description}</FormText>
                                        }
                                    </div>

                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <label className="el-switch el-switch-red" htmlFor="status">
                                            <span className="margin-l">مستلمه</span>
                                            <input type="checkbox"
                                                   name="status"
                                                   id="status"
                                                   checked={active}
                                                   onChange={handleChange}
                                            />
                                            <span className="el-switch-style"/>
                                        </label>
                                    </div>
                                </div>
                                <div className="form-row">
                                    {state.errors.response &&
                                    <FormText>{state.errors.response}</FormText>
                                    }
                                </div>
                                <div className="form-row">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col col-footer">
                                                <div className="buttons">
                                                    <Button
                                                        handleClick={handleSubmit}
                                                        type="submit"
                                                        className="green"
                                                    >
                                                        <span className="icon-Save"/>تسجیل
                                                    </Button>
                                                    <Button
                                                        handleClick={() => {
                                                        }}
                                                        className="blue"
                                                    >
                                                        <span className="icon-Cancel"/>الغاء
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

    const breadcrumb = [
        {
            link: "/",
            name: "خانه"
        },
        {
            link: props.mode === "in" ? "/cheque-in" : "/cheque-out",
            name: props.mode === "in" ? "الحواله الوارده" : "الحواله الصادر",
        }
    ]


    return (
        <div>
            <Breadcrumb data={breadcrumb}/>
            <form className="form" id="cheque-form">
                <div className="form-row">
                    {render()}
                    {renderIns()}
                    {renderOuts()}
                </div>
            </form>
        </div>

    )
};

export default Cheque
