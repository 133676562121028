import React, {useEffect, useState} from "react";
import './List.css';
import Datatable from "../dataTable/DataTable";
import dataService from "../../services/data.service";
import Token from "../auth/Token";
import moment from "moment";

const List = (props) => {
    const config = {
        create: {
            link: './create',
            icon: 'icon-Plus',
            title: 'انشاء جرد'
        }
    };
    const columns = [
        {
            key: 'id',
            title: 'الترتیب',
            value: function (model) {
                return model.id;
            }
        },
        {
            key: 'created_at',
            title: 'تاريخ الانشاء',
            value: function (model) {
                return moment(model.created_at).format("YYYY/MM/DD hh:mm:ss");
            }
        }
    ];
    const actions = [
        {
            link: '/jard/update/{id}',
            icon: 'icon icon-Edit',
            type : 'link'
        },
        {
            link: '/jard/delete/{id}',
            icon: 'icon icon-Cancel',
            type : 'delete'
        }
    ];
    const {token} = Token();
    const ds = new dataService(token, 'jard');
    return (
        <div class="col-md-12">
            <Datatable entity="users"
                       dataService={ds}
                       columns={columns}
                       actions={actions}
                       config={config}/>
        </div>
    )
};

export default List
