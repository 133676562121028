import React, {useEffect, useState} from "react";
import dataService from "../../services/data.service";
import Token from "../auth/Token";
import {useNavigate, useParams} from "react-router";
import translate from "../../message/avii";
import {isContainWhiteSpace, isEmpty, isLength, isUsername} from "../../shared/validator";
import {NotificationManager} from "react-notifications";
import Breadcrumb from "../Breadcrumb";
import {FormText} from "react-bootstrap";

const CustomerForm = (props) => {
    const [state, setState] = useState({
        formData: {
            id: null,
            code: Math.floor(100000 + (Math.random() * (1000000 - 100000))),
            name: null,
            identificationNumber: null,
            mobile: null,
            email: null,
            fax: null,
            title: null,
            order: null,
            description: null,
            status: 1,
        },
        errors: {},
        formSubmitted: false,
        loading: false
    });
    let {Id} = useParams();
    const navigate = useNavigate();
    const {token} = Token();
    const modelDataService = new dataService(token, 'customers');

    useEffect(() => {
        if (Id) {
            modelDataService.get(Id)
                .then(result => {
                    let user = result.data.data;
                    setState({
                        ...state,
                        formData: user
                    });
                });
        }
    }, []);


    function handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        let {formData} = state;
        formData[name] = value;

        setState({
            ...state,
            formData: formData
        });
    }

    const validateLoginForm = () => {

        let errors = {};
        const {formData} = state;

        //name
        if (isEmpty(formData.name)) {
            errors.name = translate.ar.forms["name can't be blank"];
        }
        if (isEmpty(formData.email)) {
            errors.email = translate.ar.forms["email can't be blank"];
        }
        if (isEmpty(formData.mobile)) {
            errors.mobile = translate.ar.forms["mobile can't be blank"];
        }
        if (isEmpty(formData.identificationNumber)) {
            errors.identificationNumber = translate.ar.forms["identificationNumber can't be blank"];
        }


        if (isEmpty(errors)) {
            return true;
        } else {
            return errors;
        }
    };

    async function handleSubmit(event) {
        event.preventDefault();
        let errors = validateLoginForm();

        if (errors === true) {
            saveCustomer();
        } else {
            setState({
                ...state,
                errors: errors,
                formSubmitted: true
            });
        }
    }


    function saveCustomer() {

        const {formData} = state;

        let title;
        let message="";
        let error=translate.ar.global["error"];
        if (Id){
            message=translate.ar.forms["Updated successfully"];
            title=translate.ar.global["update"];
        }else{
            message=translate.ar.forms["created successfully"]
            title=translate.ar.global["create"];
        }

        if (formData.id) {
            modelDataService.update(formData.id, formData)
                .then(response => {
                    this.setState(...state, {
                        id: response.data.id,
                        name: response.data.name
                    });
                    NotificationManager.success(message, title);
                    navigate("/customer")
                })
                .catch(e => {
                    NotificationManager.error(e.message, title);
                });
        } else {
            modelDataService.create(formData)
                .then(response => {
                    if (response.data.success) {
                        NotificationManager.success(message, title);
                        navigate("/customer")
                    } else {
                        setState({
                            ...state,
                            errors: response.data,
                            formSubmitted: true
                        });
                        navigate("/customer")
                    }
                })
                .catch(e => {
                    NotificationManager.error(error,title );
                });
        }


    }
    const  breadcrumb=[
        {
            link: "/",
            name: "الرئيسية"
        },
        {
            link: "/setting",
            name: "اعدادات"
        },
        {
            link: "/customer",
            name: "قائمة"
        },
        {
            link:Id?"/customer/update/"+Id:"/customer/create",
            name:Id?"تعدیل":"انشاء"
        }
    ]

    return (
        <div>
            <Breadcrumb data={breadcrumb}/>
            <form className="form card" onSubmit={(el) => handleSubmit(el)}>
                <div className="form-row  card-body">
                    <div className="col-md-4 form-group">
                        <label htmlFor="code">رقم المشتری</label>
                        <input name="code"
                               id="code"
                               type="text"
                               onChange={handleChange}
                               defaultValue={state.formData.code}
                               className="form-control silver"/>
                        {state.errors.code &&
                        <FormText>{state.errors.code}</FormText>
                        }
                    </div>

                    <div className="col-md-4 form-group">
                        <label htmlFor="name">اسم المشتری</label>
                        <input name="name"
                               id="name"
                               type="text"
                               onChange={handleChange}
                               defaultValue={state.formData.name}
                               className="form-control silver"/>
                        {state.errors.name &&
                        <FormText>{state.errors.name}</FormText>
                        }
                    </div>

                    <div className="col-md-4 form-group">
                        <label htmlFor="identificationNumber">رقم الهویه</label>
                        <input
                            type="text"
                            className="silver form-control"
                            onChange={handleChange}
                            name="identificationNumber"
                            id="identificationNumber"
                            defaultValue={state.formData.identificationNumber}/>
                        {state.errors.identificationNumber &&
                        <FormText>{state.errors.identificationNumber}</FormText>
                        }
                    </div>
                    <div className="col-md-4 form-group">
                        <label htmlFor="mobile">موبایل</label>
                        <input
                            type="text"
                            className="silver form-control"
                            id="mobile"
                            name="mobile"
                            onChange={handleChange}
                            defaultValue={state.formData.mobile}/>
                        {state.errors.mobile &&
                        <FormText>{state.errors.mobile}</FormText>
                        }
                    </div>

                    <div className="col-md-4 form-group">
                        <label htmlFor="mobile">الفاکس</label>
                        <input
                            type="text"
                            className="silver form-control"
                            id="fax"
                            name="fax"
                            onChange={handleChange}
                            defaultValue={state.formData.fax}/>
                        {state.errors.fax &&
                        <FormText>{state.errors.fax}</FormText>
                        }
                    </div>

                    <div className="col-md-4 form-group">
                        <label htmlFor="email">الایمیل</label>
                        <input
                            type="text"
                            className="silver form-control"
                            id="email"
                            name="email"
                            onChange={handleChange}
                            defaultValue={state.formData.email}/>
                        {state.errors.email &&
                        <FormText>{state.errors.email}</FormText>
                        }
                    </div>

                    <div className="col-md-8 form-group">
                        <label htmlFor="title">العنوان</label>
                        <input
                            type="text"
                            className="silver form-control"
                            id="title"
                            name="title"
                            onChange={handleChange}
                            defaultValue={state.formData.title}/>
                        {state.errors.title &&
                        <FormText>{state.errors.title}</FormText>
                        }
                    </div>

                    <div className="col-md-4 form-group">
                        <label htmlFor="order">الترتیب</label>
                        <input
                            type="text"
                            className="silver form-control"
                            id="order"
                            name="order"
                            onChange={handleChange}
                            defaultValue={state.formData.order}/>
                        {state.errors.order &&
                        <FormText>{state.errors.order}</FormText>
                        }
                    </div>

                    <div className="col-md-6 form-group">
                        <label htmlFor="description">التفاضیل</label>
                        <textarea
                            name="description"
                            id="description"
                            onChange={handleChange}
                            cols="10"
                            rows="5"
                            className="silver form-control"
                            defaultValue={state.formData.description}/>
                        {state.errors.description &&
                        <FormText>{state.errors.description}</FormText>
                        }

                    </div>
                    <div className="col-md-12 form-group">
                        <button type="submit" className="btn btn-sm btn-success">تسجیل</button>
                        <button type="cancel" className="btn btn-sm btn-danger">الغا</button>
                    </div>
                </div>
            </form>
        </div>

    )
};

export default CustomerForm
